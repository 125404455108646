<template>
  <v-container
    class="payment-type payment-type-nexi ticket-type-restaurant px-0 px-sm-auto"
  >
    <br />
    <v-card class="order-info">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="4" md="2">
            <span>N° Ordine</span>
          </v-col>
          <v-col cols="4" md="4">
            <span>TOTALE (preautorizzato)</span>
          </v-col>
          <v-col cols="4" md="2">Data e ora di ritiro:</v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters class="align-center">
          <!-- N° ordine -->
          <v-col cols="4" md="2">
            <span class="order-number">#{{ this.$route.params.order }}</span>
          </v-col>
          <!-- stato -->
          <v-col cols="4" md="4">
            {{ $n(this.$route.params.orderGrossTotal, "currency") }}
          </v-col>
          <!-- fattura -->
          <v-col cols="4" md="2">
            {{
              $dayjs(
                this.$route.params.timeSlotDate +
                  " " +
                  this.$route.params.timeSlotTime,
                "YYYY-MM-DD hh:mm:ss A"
              ).format("D MMMM")
            }}

            {{ this.$route.params.timeSlotTime }}
          </v-col>
          <!-- buttons -->
          <v-col
            cols="12"
            md="6"
            class="d-flex justify-md-end buttons-container justify-start"
          >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <!--
     
      @token="tokenCreated"
      {{  this.data }}
    -->

    <br />

    <div v-if="data.showSinglePayment">
      <stripe-element-payment
        ref="paymentRef"
        :pk="pk"
        :elements-options="elementsOptions"
        :confirm-params="confirmParams"
      />
      <br />
      <button @click="pay">Paga Ora</button>
    </div>

    <br />

    <!--
    <div>
      <stripe-element-card
      ref="elementCardRef"
      :pk="pk"
      />
      <button @click="redirectRecurrent">stripe-element-card</button>
      
      {{ pk }}
      
    </div>
    <br>
    
      :value="recurrentValue2"
      
    StripeVueCard
    
    <div>
      <StripeVueCard></StripeVueCard>
    </div>
    
    <div>
      <card class='stripe-card'
      :class='{ complete }'
      :stripe="pk"
      :options='stripeOptions'
      @change='complete = $event.complete'
      />
      <button class='pay-with-stripe' @click='pay' :disabled='!complete'>Pay with credit card</button>
      
    </div>
    
    checkout elements
    
    <br>
    <div>
        <label>Card Number</label>
        <div id="card-number"></div>
        <label>Card Expiry</label>
        <div id="card-expiry"></div>
        <label>Card CVC</label>
        <div id="card-cvc"></div>
        <div id="card-error"></div>
        <button id="custom-button" @click="createToken">Generate Token</button>
      </div>
  
      <br>
    
  -->

    <br />
    <div v-if="!data.showSinglePayment">
      <PaymentAuthList
        :paymentTypeId="25"
        :showRemoveCard="false"
        :filterByUserPaymentAuthId="userPaymentAuthId"
        :showAddNewCardWarning="false"
      />
      <br />
      <button @click="redirectRecurrent">Paga Ora con Carta Selezionata</button>
    </div>
  </v-container>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";

import PaymentAuthList from "~/components/payment/gateways/PaymentAuthList.vue";

export default {
  components: {
    StripeElementPayment,
    //   StripeElementCard,
    PaymentAuthList
  },

  data() {
    return {
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      userPaymentAuthId: this.$route.params.payment_auth_id
        ? parseInt(this.$route.params.payment_auth_id)
        : null,
      pk: this.$route.params.payment_data,
      elementsOptions: {
        appearance: {} // appearance options
      },
      data: {
        showSinglePayment: true,
        pk: this.$route.params.payment_data,
        userPaymentAuthId: this.$route.params.payment_auth_id
          ? parseInt(this.$route.params.payment_auth_id)
          : null
      },
      confirmParams: {
        return_url:
          process.env.VUE_APP_EBSN_URL +
          "/ebsn/api/stripe/payment-feedback?&id=" +
          this.$route.params.id
        //return_url: 'http://localhost:8080'+'/ebsn/api/stripe/payment-feedback?&id='+this.$route.params.id,
        //return_url: 'https://laspesaonlinedev.eurospin.it'+'/ebsn/api/stripe/payment-feedback?&id='+this.$route.params.id,
      },
      stripeOptions: {}
    };
  },
  mounted() {
    if (this.$route.params.payment_auth_id) {
      this.data.showSinglePayment = false;
    } else {
      this.data.showSinglePayment = true;
    }

    this.preparePaymentIntent();
    //this.redirectRecurrent();
    this.loadCard();
  },
  methods: {
    async preparePaymentIntent() {
      this.elementsOptions.clientSecret = this.$route.params.payment_client_secret;
    },
    async loadCard() {
      /*
        if(!this.$route.params.redirect_url_recurrent ){
        return;
        }
        console.log("pk " +this.pk );
 
        //const stripe = require('stripe')('sk_test_51M9XAhE42d6M8Uy4xuGBeKRp8AByTvXOsJfN7e4BOmLQ0cl8XFrwJwFLuQ4f1Rc10JIw9QspKelDFyTFgg89DUis00CsAq35nf');
        const stripePromise = await loadStripe(this.pk);
 
        const card = await stripePromise.listSources(
          'cus_N7AIKQDrhIwWR0',
          {object: 'card', limit: 3}
        );
       console.log(card);
       var myPk = "" +this.$route.params.payment_data;
       console.log(myPk);
       console.log(this.pk);
       
       const stripePromise = await loadStripe(myPk);
       
       console.log(stripePromise);
       */
    },

    async redirectRecurrent() {
      if (this.$route.params.redirect_url_recurrent) {
        this.data.showSinglePayment = false;
        this.loading = true;
        window.location = this.$route.params.redirect_url_recurrent;
        /* 
          StripeElementPayment.confirmCardPayment(
            this.$route.params.payment_client_secret,
              {
                payment_method: {card: cardElement},
                return_url: this.$route.params.redirect_url_recurrent
              },
              // Disable the default next action handling.
              {handleActions: false}
            ).then(function(result) {
              console.log(result);
              // Handle result.error or result.paymentIntent
              // More details in Step 2.
            });
          */

        // var paymentMethodId = this.$route.params.payment_method_id ;
        //var stripe = Stripe(this.$route.params.payment_data);
        /*
          const options = {
            pk: this.$route.params.payment_data,
            testMode: true, // Boolean. To override the insecure host warning
          // stripeAccount: process.env.STRIPE_ACCOUNT,
          // apiVersion: process.env.API_VERSION,
          // locale: process.env.LOCALE,
          };
**/
        //  var myPk = "" +this.$route.params.payment_data;
        //  const stripePromise = await loadStripe(myPk);
        // this.stripe = await loadStripe(this.$route.params.payment_data, stripeOptions);

        /*
          this.stripePromise
            .confirmCardPayment(this.$route.params.payment_client_secret, {
              payment_method: paymentMethodId
            } )
            .then(function(result) {
              console.log(result);
              // Handle result.error or result.paymentIntent   , {props:{ pk:this.$route.params.payment_data}}
            });
        */

        this.loading = true;
      } else {
        this.data.showSinglePayment = true;
      }
    },

    pay() {
      this.$refs.paymentRef.submit();
    },
    formatDate(timeslotDate, timeSlotTime) {
      let day = timeslotDate + " " + timeSlotTime;
      if (this.$dayjs(day).isValid()) {
        let beginFormat = this.$dayjs(day).format("dddd D MMMM");
        return `${beginFormat} `;
      }
      return day;
    }
  }
};
</script>
<style scoped lang="scss">
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
